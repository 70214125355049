import { defineStore } from 'pinia'
import { simseiApi } from '@/residency/app-props'
import noCache from '@/residency/consts/nocache-headers.js'

export const useDashboardProgressStore = defineStore('dashboardProgress', {
  state: () => ({
    sortedCourseProgress: []
  }),
  actions: {
    async getDashboardCourseProgressAndSort (userGroupId) {
      const response = await simseiApi.get('/dashboard-progress/aggregate-progress/', {
        params: {
          userGroupId: userGroupId
        },
        headers: {
          ...noCache
        }
      })

      const coursesObj = response.data
      const coursesArray = []

      // Convert the courses Obj into an array and sort it by courseOrder
      Object.values(coursesObj).forEach(course => {
        if (!course.isPrepCourse) {
          coursesArray.push(course)
          course.activities = Object.values(course.activities).sort((a, b) => a.activityOrder - b.activityOrder)
        }
      })

      coursesArray.sort((a, b) => a.courseOrder - b.courseOrder)
      this.sortedCourseProgress = coursesArray
    },
    async fetchCourseAverages ({ courseId, groupId }) {
      const res = await simseiApi.get('/dashboard-progress/average-course-progress', {
        params: {
          courseId: courseId,
          gid: groupId
        }
      })

      const courseAverages = res.data
      courseAverages.avgGroupProgressPercentage = Math.trunc(courseAverages.avgGroupProgressPercentage)
      return courseAverages
    },
    async fetchResidentCourseProgress ({ userId, courseId, groupId }) {
      const response = await simseiApi.get('/dashboard-progress/user-course-progress', {
        params: {
          userId: userId,
          courseId: courseId,
          userGroupId: groupId
        },
        headers: {
          ...noCache
        }
      })

      const course = response.data
      // convert course.activities from map to array and sort by activity order
      if (course.activities) {
        course.activities = Object.values(course.activities).sort((a, b) => a.activityOrder - b.activityOrder)
      }

      return course
    }
  }
})
