/**
 * This composable enables you to register a callback function that will be called before the route changes. This is
 * useful because the vue-router beforeRouteLeave hook is only called from top-level components, and not from child
 * components.
 *
 * Call useBeforeRouteChange() in the setup hook of a component. Then, in the mounted or created hook of the component,
 * call this.setBeforeRouteChangeCallback(this.myCallback). The callback function will be called with
 * the same arguments as the vue-router beforeRouteLeave hook, allowing you to proceed or cancel the route change.
*/
import { nextTick } from 'process'
import { onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'

export function useBeforeRouteChange () {
  let registeredCallback = null
  let backButtonUsed = false
  let forwardButtonUsed = false
  let removeHook = null
  let navigationInfo = null
  const router = useRouter()

  router.options.history.listen((to, from, info) => {
    navigationInfo = info
  })

  const setBeforeRouteChangeCallback = (callback) => {
    registeredCallback = callback
  }

  nextTick(() => {
    removeHook = router.beforeEach((to, from, next) => {
      if (registeredCallback) {
        backButtonUsed = navigationInfo?.direction === 'back'
        forwardButtonUsed = navigationInfo?.direction === 'forward'
        registeredCallback(to, from, next, backButtonUsed, forwardButtonUsed)
      } else {
        next()
      }
      setTimeout(() => { backButtonUsed = false })
    })
  })

  onBeforeUnmount(() => {
    if (removeHook) removeHook()
  })

  return {
    setBeforeRouteChangeCallback
  }
}
